import React from 'react';
import Helmet from 'react-helmet';
import App from './_app';
import { graphql } from 'gatsby';
import { Mutation } from 'react-apollo';
import ResumeForm from '../forms/resume';
import { ContainerMedium } from '../components/Container';
import { RESUME } from '..//queries/public';
import Alert from '../components/Alert';
import Wrap from '../components/Wrap';
import Hero from '../components/Hero';
import Grid from '../components/Grid';
import Panel from '../components/Panel';

const Contact = ({ data }) => (
    <App>
        <Helmet>
            <title>{data.page.title}</title>
            <meta name="description" content={data.page.description.description} />
        </Helmet>
        <Hero title={data.page.heading} image={data.page.image} />
        <Wrap muted>
            <ContainerMedium>
                <Mutation mutation={RESUME}>
                    {(query, { error, data }) => (<div>
                        <ResumeForm action={query} />
                        {error && <Alert>Could not send the message. Please try again.</Alert>}
                        {data && <Alert success>Message received. Thank you and we will be in touch soon.</Alert>}
                    </div>)}
                </Mutation>
            </ContainerMedium>
        </Wrap>

        <ContainerMedium>
            <Grid>
                {data.related.edges.map(({ node }, i) => (
                    <Panel
                        primary
                        key={i}
                        data={Object.assign(node, {
                            url: `/candidate-services/${node.url}`
                        })}
                        image={node.image}
                    />
                ))}
            </Grid>
        </ContainerMedium>
    </App>
);

export default Contact;
export const query = graphql`
    query {

        page: contentfulPage(url: {eq: "resume"}){
            title
            heading
            description {
                description 
            }
            image {
                title
                fluid(maxWidth: 1200, quality: 100){
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }

        related: allContentfulPage(filter: {url: {in: ["interviews", "resume-tips", "register"]}}) {
            edges {
                node {
                    url
                    title
                    lead {
                        lead
                    }
                    image {
                        title
                        fluid(maxWidth: 1200, quality: 100){
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }

        site {
            siteMetadata {
                local
                tollFree 
                email 
                fax 
            }
        }

    }
`;
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    MdPersonPin,
    MdEmail,
    MdFileUpload,
} from 'react-icons/md';
import Submit from '../components/Submit';
import Grid, { Auto } from '../components/Grid';


const ContactForm = ({ action }) => (
    <Formik

        initialValues={{
            email: '',
            name: '',
            resume: ''
        }}

        validationSchema={
            Yup.object({
                name: Yup.string()
                    .required('Name is required'),
                email: Yup.string()
                    .email()
                    .required('Email is required'),
                resume: Yup.mixed()
                    .test('fileSize', 'File Size is too large', value => value.size <= 2097152)
                    .required('Resume is required')
            })
        }

        onSubmit={(variables, actions) =>
            action({ variables })
                .then(() => actions.resetForm())
        }

        render={({ setFieldValue, setFieldError, values }) => (
            <Form>
                <Grid style={{ alignItems: 'center  ' }}>
                    <Auto>
                        <label>
                            Name *
                            <Field name="name" type="text" required />
                            <ErrorMessage component="span" name="name" />
                            <MdPersonPin />
                        </label>
                    </Auto>
                    <Auto>
                        <label>
                            Email *
                            <Field name="email" type="email" required />
                            <ErrorMessage component="span" name="email" />
                            <MdEmail />
                        </label>
                    </Auto>
                    <Auto>
                        <label style={{ minWidth: 200, maxWidth: 200 }}>
                            Attachment *
                            <Field
                                name="resume"
                                render={({ field }) => (
                                    <span tabIndex="0" className="file">
                                        <input
                                            type="file"
                                            required
                                            onChange={({ target: { files: [file] } }) =>
                                                setFieldValue(field.name, file)
                                            }
                                        />

                                        {values.resume ? values.resume.name : 'Select file'}
                                        <ErrorMessage component="span" name="resume" />
                                    </span>
                                )}
                            />
                            <MdFileUpload />
                        </label>
                    </Auto>
                    <Submit text="Submit" />
                </Grid>
            </Form>
        )}
    />);

ContactForm.propTypes = {
    action: PropTypes.func
};

export default ContactForm;
import gql from "graphql-tag";

export const CONTACT = gql`
  mutation contact(
    $email: Email!
    $name: String!
    $phone: Phone
    $comment: String
  ) {
    contact(email: $email, name: $name, phone: $phone, comment: $comment) {
      ok
    }
  }
`;

export const RESUME = gql`
  mutation resume($email: Email!, $name: String!, $resume: Upload) {
    resume(email: $email, name: $name, resume: $resume) {
      ok
    }
  }
`;

export const INQUIRY = gql`
  mutation inquire(
    $name: String!
    $company: String!
    $email: Email!
    $phone: Phone!
    $description: String
    $roleType: String!
    $compensationType: String!
  ) {
    inquire(
      name: $name
      company: $company
      email: $email
      phone: $phone
      description: $description
      roleType: $roleType
      compensationType: $compensationType
    ) {
      ok
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import { Overlay } from './Slider';
import Button from './Button';
import Icon from './Icon';
import withMedia from './withMedia';

const Panel = styled.article`
    border-radius: 15px;
    box-shadow: 0 1px 33px rgba(0,0,0,0.15);
    box-sizing: border-box;
    height: ${props => props.lead ? '475px' : '300px'};  
    margin: 1rem auto;
    overflow: hidden;
    padding: 2rem;
    position: relative;
    width: 400px;

    ${withMedia('laptop', css`
        width: 350px;
    `)}

    ${withMedia('tablet', css`
        width: 300px;
    `)}

    ${withMedia('phone', css`
        width: 90%;
    `)}

    .gatsby-image-wrapper {
        bottom: 0;
        left: 0;
        position: absolute !important;
        right: 0;
        top: 0; 
    }
`;

const Content = styled.div`
    color: #FFF; 
    position: relative;

    h2 { margin-top: 1rem }
`;

const BottomButton = styled(Button)`
    bottom: 2rem;
    position: absolute;
`;

const PanelIcon = styled(Icon)`
    height: 2rem;
    fill: #FFF;
    width: 2rem;
`;

const PanelComp = props => (<Panel lead={props.data.lead}>

    {props.image && <Image
        fluid={props.image.fluid}
        alt={props.image.title}
    />
    }

    <Overlay primary={props.primary} />
    <Content>
        <PanelIcon name={props.data.icon} />
        <h2>{props.data.title}</h2>
        <p>{props.data.lead ? props.data.lead.lead : ''}</p>
    </Content>

    <BottomButton to={props.data.url} primary>Learn more</BottomButton>
</Panel>);

PanelComp.propTypes = {
    image: PropTypes.object,
    data: PropTypes.object
};

export default PanelComp;